import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'grid'
    },{
      title: 'Benutzer',
      url: '/users',
      icon: 'people'
    },
    {
      title: 'Streams',
      url: '/streams',
      icon: 'videocam'
    }
  ];

  public loggedIn:boolean = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router,
    private menu: MenuController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // Detect Login Changes
      this.authenticationService.authenticationState.subscribe(state => {
        if (state) {
          this.loggedIn = true;
          this.router.navigate(['dashboard'], {replaceUrl: true});
        } else {
          this.loggedIn = false;
          this.router.navigate(['login'], {replaceUrl: true});
        }
      });

      this.router.events.subscribe((val) => {
        this.setNavigationHighlight();
      });
    });
  }

  ngOnInit() {
    this.setNavigationHighlight();
  }

  setNavigationHighlight(){
    this.selectedIndex = 0;
    if (window.location.pathname !== "/") {
      this.selectedIndex = this.appPages.findIndex(page => page.url.toLowerCase() === window.location.pathname.toLowerCase());
    }
  }

  logout(){
    this.menu.close('first');
    this.authenticationService.logout();

  }
}
