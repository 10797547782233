import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authenticationState = new BehaviorSubject(false);

  constructor(private storage: Storage, private platform: Platform) {
    this.platform.ready().then(() => {
      this.checkToken();
    });
  }

  //TODO: valid or expired?
  checkToken() {
    console.log("gogo");
    this.storage.get(TOKEN_KEY).then(res => {
      console.log(res);
      if (res) {
        this.authenticationState.next(true);
      }
    })
  }
 

  login() {
    return this.storage.set(TOKEN_KEY, 'SAMPLETOKEN').then(() => {
      this.authenticationState.next(true);
    });
  }
 
  logout() {
    return this.storage.remove(TOKEN_KEY).then(() => {
      this.authenticationState.next(false);
    });
  }
 
  isAuthenticated() {
    console.log("isAuthenticated");
    return this.authenticationState.value;
  }
}
